.conversation--send-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;

  .conversation--send-msg-btn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
