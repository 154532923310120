@import "../../assets/stylesheets/variables";

.lightbox-modal {
  background: rgba($colorBlack, 0.2);

  .modal-content {
    background: transparent;
    box-shadow: none;
    border: none;
    text-align: center;

    .spinner-image {
      width: 170px;
      height: 170px;
      font-size: 48px;
      border-radius: 16px;
      opacity: 0.8;
    }

    .loader {
      border: 16px solid $colorInactiveGrey; /* Light grey */
      border-top: 16px solid $colorPrimary; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
      margin: auto;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}
