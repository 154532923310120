@import "/src/assets/stylesheets/variables.scss";
.registration-landing-page-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-top: -20px;
  min-height: calc(100vh - 60px);
  .image-and-process-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    .background-image-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      width: 100%;
      height: 245px;
      background-image: url(../../assets/images/heroimage.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      color: $colorWhite;;
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        height: 293px;
      }
      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        height: 522px;
      }
      .header-content {
        top: 30px;
        font-size: 30px;
        line-height: 34px;
        font-weight: 500;
        @media screen and (min-width: 1024px) and (max-width: 1440px) {
          font-size: 35px;
          line-height: 45px;
        }
        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 65px;
          line-height: 85px;
        }
      }
    }
    .portal-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto; //for inner arrangement
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: $colorBlack;
      color: $colorWhite;
      font-size: 12px;
      font-weight: 100;
      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        font-size: 24px;
      }
      .patient-learn-more-container,
      .provider-learn-more-container,
      .combination-content {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
      }
      .patient-learn-more-container {
        font-size: 14px;
        .icon {
          position: absolute;
          left: 15px;
          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }
        .link {
          display: block;
          color: $colorPrimary;
          font-weight: 500;
        }
      }
      .provider-learn-more-container {
        font-size: 14px;
        margin-top: 10px;
        .icon {
          position: absolute;
          left: 15px;
          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }
        .link {
          display: block;
          color: $colorPrimary;
          font-weight: 500;
        }
      }
      .combination-content {
        font-size: 12px;
        text-align: center;
        margin-top: 15px;
        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 24px;
        }
      }
    }
  }
}

.success-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 50px;
  min-height: calc(100vh - 200px);
  text-align: center;
  @media screen and (max-width: 767px) and (orientation: landscape) {
    margin: 0;
    min-height: calc(100vh - 90px);
  }
  @media screen and (max-width: 572px) {
    margin: 0;
    min-height: calc(100vh - 90px);
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-height: calc(100vh - 200px);
  }
  .success-icon {
    .icon:before {
      font-size: 60px;
      color: $colorAlertPositive;
      @media screen and (max-width: 572px) {
        font-size: 40px;
      }
    }
  }
  .header-font {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
    @media screen and (max-width: 572px) {
      font-size: 30px;
    }
  }
  .finalize-setup-label {
    font-size: 16px;
    font-weight: 300;
    width: 400px;
    margin: 10px 0;
    @media screen and (max-width: 572px) {
      width: 250px;
      font-size: 15px;
    }
  }
  .phone-verified-label,
  .email-verified-label {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    width: 400px;
    margin: 20px 0;
    @media screen and (max-width: 572px) {
      width: 250px;
    }
    .icon:before {
      font-size: 20px;
      top: 5px;
      left: -8px;
    }
    span {
      text-align: left;
      margin-left: 10px;
    }
  }
  div {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}

.success-page-logo-container {
  position: absolute;
  top: 40px;
  left: 40px;
  img {
    width: 200px;
  }
  @media screen and (max-width: 572px) {
    top: 40px;
    left: 80px;
    img {
      width: 150px;
    }
  }
}

.mobile-view-container {
  .mobile-footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
    background: $colorSecondary;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 300;
    .contact-us {
      color: $colorPrimary;
      margin-right: 30px;
      float: left;
    }
    .about-us {
      color: $colorPrimary;
      margin-left: 10px;
      float: left;
    }
    .dot {
      margin-left: 10px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: $colorInactiveGrey;
      float: left;
    }
    .contact-number {
      color: $colorInactiveGrey;
      margin-left: 10px;
      float: left;
    }
  }
}
