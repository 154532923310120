@import "../../assets/stylesheets/variables";
.navbar-form {
  .form-group {

    .single-select__control {
      width: 100px;
      border: 1px solid $colorPrimary;
      border-right: 0px;
      border-radius: 5px 0px 0px 5px;
      min-height: 30px !important;
      height: 32px !important;
      font-size: 14px;

      @media screen and (min-width: 821px) and (max-width: 1024px) {
        width: 75px;
      }
      @media screen and (min-width: 768px) and (max-width: 820px) {
        width: 55px;
      }
    }
    .single-select__menu {
      min-width: 150px;
      font-size: 14px;
    }
  }
}
