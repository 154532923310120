@import "/src/assets/stylesheets/variables.scss";

.app-container {
  height: 100% !important;
}

#zipCode {
  text-transform: uppercase;
}

.PrivatePickersYear-yearButton {
  font-size: 14px !important;
}

.patient-register-container {
  background: -webkit-linear-gradient(
    to bottom,
    $themeRedColor 1%,
    $themeBlueColor 99%
  );
  background: -moz-linear-gradient(to bottom, $themeRedColor 1%, $themeBlueColor 99%);
  background: -o-linear-gradient(to bottom, $themeRedColor 1%, $themeBlueColor 99%);
  background: linear-gradient(to top, $themeBlueColor 1%, $themeBlueColor 99%);
  padding-top: 40px;
  color: $colorBlack;
  min-height: 100vh;
  margin-top: -83px;

  .btn-primary {
    background-color: $themeRedColor;
    border: 1px solid $themeRedColor;
    height: 35px;
    border-radius: 20px;
    padding: 0px 20px;
    font-weight: 300;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      height: 30px;
    }
    &:hover {
      background-color: $themeRedColor;
      border: 1px solid $themeRedColor;
      .icon:before {
        color: $colorWhite;
      }
    }
  }

  .btn-primary:active,
  .btn-primary:focus {
    background-color: $themeRedColor !important;
    &.red {
      background-color: $colorAlertNegative !important;
      border: 1px solid $colorAlertNegative;
    }
    color: $colorWhite !important;
    border: 1px solid $themeRedColor;
  }

  .btn.btn-primary[disabled],
  .btn.btn-primary[disabled]:hover {
    background-color: $colorDisabledGrey !important;
    border: 1px solid $colorDisabledGrey !important;
  }

  .input-container {
    text-align: left;
  }
  .welcome-section {
    color: $colorWhite;
    .text {
      font-size: 20px;
    }
  }
  .copyright-container {
    margin: 10px 15px 20px;
    padding: 30px 15px;
    position: relative;
    color: $colorWhite;
    font-size: 12px;
    font-weight: 100;
    text-align: center;
  }

  .secondary-logo {
    height: 80px;
    margin-top: 10px;
  }

  @media screen and (min-width: 800px) and (max-width: 1280px) {
    .secondary-logo {
      height: 120px;
      height: 200px;
    }
  }

  @media screen and (min-width: 200px) and (max-width: 799px) {
    .secondary-logo {
      height: 120px;
      margin-top: 10px;
    }
  }

  .inline-italic-txt {
    font-style: italic;
  }

  .steps-container {
    margin: 40px 30% 20px;
    padding: 30px 15px;
    background-color: $colorWhite;
    position: relative;
    color: $colorBlack;
    font-size: 16px;
    font-weight: 100;

    @media screen and (min-width: 200px) and (max-width: 799px) {
      margin: 40px 5% 20px;
    }

    @media screen and (min-width: 800px) and (max-width: 1280px) {
      margin: 40px 10% 20px;
    }

    .phone-number {
      font-size: 20px;
      font-weight: 100%;
    }

    .step-number {
      position: absolute;
      right: 10px;
      top: 17px;
      color: $themeLightGrayColor;
    }
    .prev-link {
      position: absolute;
      left: 10px;
      top: 10px;
      color: $themeLightGrayColor;
      .icon::before {
        top: 6px;
      }
    }
    .step-section {
      margin-top: 10px;
      .names {
        margin-top: 4%;
      }
    }
    h2 {
      margin-top: 10px;
    }
    .input-label-without-value {
      font-size: 20px;
      top: 0;
    }
    input {
      background-color: inherit;
      color: $colorBlack;
      font-size: 20px !important;
      border-bottom-color: $colorBlack !important;
      width: 100%;
    }
    .single-select {
      &__placeholder,
      &__single-value {
        width: 100%;
        font-size: 20px !important;
        text-align: left;
      }
      &__control{
        border-bottom: 1px solid $colorBlack !important;
      }
      &__option {
        font-size: 18px !important;
        text-align: left;
      }
      &__indicator {
        color: $colorBlack !important;
        opacity: 0.6;
      }
    }
    .has-error {
      .single-select__control {
        border-color: $colorAlertNegative !important;
      }
    }
    .form-group-select {
      position: relative;
      .icon {
        position: absolute;
        top: 6px;
        right: 0px;
        color: $colorBlack;
      }
    }
    .input-label-is-focused {
      color: $colorWhite !important;
    }
    .date-picker-field .form-control {
      background-color: inherit;
    }
    .info-text {
      font-size: 12px;
      font-weight: 300;
    }
    .date-field {
      border-bottom-color: $themeOrangeColor !important;
    }
    .procedure-date-view {
      .date-picker-field {
        border-bottom: none !important;
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $colorWhite;
        }
        ::placeholder {
          color: $colorWhite;
          opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $colorWhite;
        }
        .button-container {
          margin-top: 0;
        }
      }
      .btn-input {
        width: 180px;
        background-image: none;
        background: $themeRedColor;
        color: $colorWhite;
        border: none;
        border-radius: 20px;
        font-size: 18px !important;
        height: 40px;
      }
    }
  }
  .content-text {
    color: inherit;
    font-size: 16px;
    font-weight: 100;
    .main-text {
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
    }
    .more-link {
      text-decoration: underline;
      color: $themePurpleColor;
    }
  }
  .primary-logo {
    height: 40px;
  }

  .button-container {
    margin-top: 20px;
    button {
      background: #d03749;
      border: none;
      font-size: 18px;
      height: 40px;
    }
    .pain-pump-button-container {
      display: inline-block;
      margin: 20px;
      position: relative;
      .onq-logo {
        position: absolute;
        height: 80px;
        top: -15px;
        left: -8px;
      }
      .ambit-logo {
        position: absolute;
        height: 80px;
        top: -25px;
        right: 5px;
      }
      .onq-button {
        text-align: right;
      }
      .ambit-button {
        text-align: left;
      }
    }
  }
  button {
    min-width: 180px;
  }
  .contact-info {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
  }

  .edit-back-button {
    color: $themeRedColor;
    font-size: 24px;
  }

  .no-padding {
    padding: 0px;
  }

  .dot {
    width: 5px;
    height: 5px;
    background: $colorBlack;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: -2px;
    margin-right: 10px;
  }

  .check-icon > img {
    height: 15px;
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.check-icon-hide {
  display: none;
}

.password-show {
  position: absolute;
  right: 10px;
  top: 15px;
  color: $colorPrimary;
  cursor: pointer;

  &.disabled {
    color: $colorDisabledGrey;
    cursor: default;
  }
}

.passwordValidator {
  text-align: left;
  margin-left: 10%;
}

.text-left-align {
  text-align: left;
  margin-left: 20%;
}

.app-listing-logo {
  height: 80px;
  margin: 0 15px;
}

.welcomeTitleText {
  font-size: $font-size-header-xxxxl;
  font-weight: $font-weight-medium;
  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
}
.self-signup-wrapper {
  background-color: $colorSecondary;
  // height: 100vh;
}

.welcome-content-wrapper {
  padding: 90px 51px;

  .welcome-content-inner-wrapper {
    background-color: $colorWhite;
  }
}

.hero-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.welcome-info-container{
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  gap: 50px;
  justify-content: center;

  @media screen and (max-width: 992px) {
    padding: 30px;
    gap: 20px;
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    margin: 0;
    padding: 20px 25px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 480px) and (max-height: 760px){
    max-height: 70vh;
  }

  .welcome-btn-container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;

    @media screen and (max-width: 992px) {
      width: 50%;
      margin-top: auto;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.login-page-container{
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.procedure-page-container{
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dc-info-container{
  // background-color: white;
  display:flex;
  align-items: center;
  height:100vh;
  // margin-top:8vh;
  // margin-bottom:8vh;
  // height: 84vh;
  // @media screen and (max-width: 991px) {
  //   max-height: 50vh;
  //   margin: 0;
  //   padding:0;
  // }
}

.success-image {
  height: 100vh;
  width: 100%;
  @media screen and (max-width: 780px) {
    width: 100%;
    height: 50vh;
  }
}

.welcome-screen-text-wrapper {
  margin: 10% 5%;
  @media screen and (max-width: 780px) {
    margin: 0px;
  }
}

.welcome-screen-text-inner-wrapper {
  padding: 15% 9%;
  display: flex;
  background-color: $colorWhite;
  flex-direction: column;
  @media screen and (max-width: 780px) {
    padding: 0;
    height: 50vh;
  }
}
.success-screen-text-inner-wrapper {
  padding: 10% 9%;
  display: flex;
  background-color: $colorWhite;
  flex-direction: column;
  @media screen and (max-width: 780px) {
    padding: 0;
    height: 50vh;
  }
}

.welcomeSubTitleText {
  font-size:32px;
  font-weight: 400;
  line-height: 30px;

  @media (max-width: 280px) {
    font-size: 24px;
  }
}

.successSubTitleText {
  margin-bottom:8.26%;
  font-size:24px;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    margin-bottom: 3.2%;
  }
  }
.success-footer-text {
  margin-top: 50px;
  @media screen and (max-width: 780px) {
    margin: 0;
  }
  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.success-icon-text {
  margin-top: 25px;
  @media screen and (max-width: 780px) {
    margin: 0;
  }
}

.success-screen-text-wrapper {
  margin: 8% 5%;
  @media screen and (max-width: 780px) {
    margin: 0px;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
}

.i2 {
  display: grid;
  grid-template-columns: 3.5fr 93fr 3.5fr;
  grid-template-rows: 10% 80% 10%;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
}

html,
body {
  height: 100vh;
}

.container {
  height: 100%;
  margin: 0;
}

/* For presentation only, no need to copy the code below */

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
}

.i2 {
  display: grid;
  grid-template-columns: 3.5fr 93fr 3.5fr;
  grid-template-rows: 10% 80% 10%;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
}

/* For presentation only, no need to copy the code below */

.container * {
  border: 1px solid red;
  position: relative;
}

.container *:after {
  content: attr(class);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}
.mansi {
  background-color: white;
}

// .password-view-wrapper{
//   top: '48px';
//   min-height: 85vh;
// }
.password-view {
  // paddingTop: "99px",
  // paddingBottom: "80px",
  // backgroundColor: "white",
  // border: '2px solid red',
  // top: '48px',
  // bottom: '40px',
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  // margin-top: 80px;
  background-color: white;
  min-height: 825px;
  align-items: center;
}
.signup-title {
  margin-top: 9.54px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}
.secure-start-title {
  padding-top: 80px;
}
.signup-instruction-title {
  padding-top: 15px;
  /* Headline Small SS */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: $colorPrimaryDark;
}
.password-input {
  padding-top: 89px;
}

.email-verification-wrapper {
  padding-top: 99px;
  padding-bottom: 80px;
  background-color: white;
  padding-left: 17% !important;
  padding-right: 17% !important;
  height: 100vh;
}

.title-section {
  display: flex;
  align-items: center;
}

.header-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 55px;
}
.thanks-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: $colorBlack;
  margin-top: 1.2%;
  margin-bottom: 1.45%;
}
.highlight-text {
  color: $colorPrimaryDark;
}

.verification-highlight {
  color: $colorPrimary;
  font-weight: 500;
}

.otp-message {
  margin-bottom: 11%;
  font-size: 24px;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    text-align: left;
    color: $colorBlack;
    margin-top:0.4%;
    margin-bottom:2%;

  }
  }

.resend-message {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
}

.resend-code {
  margin-top: 2.5%;
}
.resend-code-disabled-text {
  color: $colorDisabledGrey;
}

.password-verify-check-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
}

.password-verify-check-box-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 13px;
}

.password-verify-check-box {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

// Disclaimer View
.dialog-box {
}
.dialog-box-title {
  text-align: center;
}
.dialog-content-wrapper > .dialog-box-para {
  margin-top: 20px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullScreen{
  height:auto;
  max-width: 500px;
}
.dialog-box-text {
  color: $colorPrimaryDark !important;
  text-align: center;
  font-size:18px !important;
  font-weight:400 !important;
}

.dialog-header{
  display: grid;
  grid-template-columns: 0.1fr 0.8fr 0.1fr;
}
.dialog-actions-wrapper{
  padding: 0 16px 16px 16px !important;
  column-gap:15px;
  justify-content: center !important;
}

@media screen and (max-width: 900px) {
  .dialog-box {
    padding-top: 41px;
  }
}

.procedure-verification-wrapper{
  text-align: center;
  background-color: $colorWhite;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 80px 14px;
}
.hr-color{
  border-top: 1px solid $colorPrimaryDark;
}

.procedure-verification-content{
  margin-top: 5%;
  font-size: 24px;
  font-weight: 400;
  color: #575558;

  @media screen and (max-width: 480px){
    text-align: left;
  }
}

.dark-highlight{
  color: $colorPrimaryDark;
  font-weight: 700;
}
.contact-number{
  white-space: nowrap;
}
.procedure-verification-button{
 margin-top: 30%;
 align-items: center;
 @media screen and (max-width: 480px){
  margin-top: 60%;
 }
}

.welcome-info{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-height:480px) and (orientation: landscape){
    height: 100vh;
    padding: 0;

    }
}

.app-url-btn-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.dc-info{
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 17%;
  justify-content:center;

  @media screen and (max-width: 991px) {
    padding: 3.2% 19% 8.5% 19%;
  }
  @media screen and (max-width: 480px) {
    padding: 3% 3.75%;
    justify-content:space-between

  }
}
.secure-start-title-text{
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;

  @media screen and (max-height:480px) and (orientation: landscape){
    margin-bottom:0;
  }

  @media (max-width: 280px) {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.success-secure-start-title-text{
  font-size:32px;
  margin-bottom:3.2%;
  font-weight: 500;
  @media screen and (max-width: 991px) {
    margin-bottom:2%;
   }
  @media screen and (max-width: 480px) {
   margin-bottom:2%;
  }
  @media screen and (max-height:480px) and (orientation: landscape){
    margin-bottom:0;
     }
}

.secure-start-help-info{
  font-size:16px;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    max-width: 60%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    text-align: left;
  }

  @media screen and (max-height:480px) and (orientation: landscape){
    margin-bottom:0;
  }
}

.success-page-secure-start-help-info{
  font-size:16px;
  font-weight: 400;
  margin-bottom:23%;
  margin-top: 8.26%;
  @media screen and (max-width: 991px) {
    margin-bottom:0;
    margin-top:0;

  }
  @media screen and (max-width: 480px) {
    margin-bottom: 8%;
    margin-top: 0;
  }
  @media screen and (max-height:480px) and (orientation: landscape){
   margin-bottom:0;
   margin-top:0;
    }
}

.procedure-screen-wrapper{
  margin-top: 24px;
}

.forgot-password-wrapper{
  margin-top: 24px;
}
.text-container-wrapper{
  text-align:center;
  display: flex;
  padding: 60px 30px;
  flex: 1;

  @media screen and (max-width: 991px) {
    padding: 0;
  }

  @media screen and (max-width: 480px) {
    text-align:left;
    padding: 0;
  }

  @media screen and (max-height:480px) and (orientation: landscape){
    padding:0;
    width: 50%;
  }

  @media screen and (max-height:480px) and (max-width: 712px) and (orientation: landscape){
    padding:0;
    width: 60%;
  }
}

.password-reset-text-wrapper{
  text-align:center;
  @media screen and (max-width: 991px) {
    padding:0;
    }
    @media screen and (max-width: 480px) {
      text-align:left;
      padding:0;
      }

}

.login-view-wrapper{
  text-align:center;
  @media screen and (max-width: 991px) {
    padding:0;
    }
    @media screen and (max-width: 480px) {
      text-align:left;
      padding:0;
      }


}


.MuiContainer-root.MuiContainer-maxWidthLg{
  @media screen and (max-width: 991px) {
    padding:0;
    }
}


.name-view-outer-wrapper{
  padding-top: 6%;
  padding-bottom: 5%;
  background-color: white;

}
.wrapper-outer-body{
  display:flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  padding: 60px;
  align-items: center;
  background-color: $colorSecondary;

  //@media (max-width: )

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 0;
  }

}

.wrapper-body{
  padding-top: 6%;
  padding-bottom: 5%;
  background-color: white;
  width:767px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    width:100%
  }
  @media screen and (max-width: 480px) {
    width:100%;
    padding-left:9%;
    padding-right:9%;
  }

  @media screen and (max-height:480px) and (orientation: landscape){
    height: 136vh;
  }

}

// .password-reset-continue-button{
//   @media screen and (max-height:480px) and (orientation: landscape){
//     margin-top: 136px;
//   }
// }

.floating-button{
  background-color: white;
  margin-left: -30.7%;
  margin-right: -30.7%;
  padding-left: 31%;
  padding-right: 31%;
  box-shadow: 0px -4px 10px rgba(138, 110, 147, 0.2);
  padding-top: 4%;
  margin-bottom: -16%;


  position: sticky;
  bottom:0;
  z-index:1;
  padding-top:15px;
  padding-bottom: 10%;

  @media screen and (max-width: 480px) {
    margin-left: -10.7%;
    margin-right: -10.7%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

.dc-view-outer-wrapper{
  padding-top: 6%;
  padding-bottom: 5%;
  background-color: white;
  @media screen and (max-width: 991px) {
   height: 100%;
   display:flex;
   flex-direction: column;
   justify-content: center;
    }
    // @media screen and (max-width: 480px) {
    //   margin-bottom: 6%;
    //   }

}

.marketing-consent-checkbox{
  padding-left: 3.87%;
}

.checkbox-label{
  display:flex;
  flex-direction: column;
  column-gap:9px;
}
.sign-up-header{
  display: flex;
  align-Items: center;
  justify-content:space-between;
  padding-left:5%;
  padding-right:5%;

  @media screen and (max-width: 480px) {
    flex-direction:column;
    align-items:flex-start;
    row-gap: 9px;
    padding:0;
  }
}



.header-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  @media screen and (max-width: 480px) {
    margin:0;
    align-self: center;
    }
}

.sign-up-welcome-great-text{
padding:0.6% 0 0.1% 0;
font-size:32px;
text-align: center;
@media screen and (max-width: 480px) {
  text-align: left;
  padding:0 0 0 4%;

  }
}

.wrapper-title-one{
  padding:0.6% 0 0.1% 0;
  font-size:32px;
  font-weight: 700;
  margin-top:20px;
  text-align: center;
  @media screen and (max-width: 480px) {
    text-align: left;
    margin-top: 31px;
    }
  }
.sign-up-welcome-start-text{
 padding:0 0 2.4% 0;
 font-size:24px;
 text-align:center;
 @media screen and (max-width: 480px) {
  text-align: left;
  padding:0 0 3% 4%;
  }
}

.wrapper-title-two{
  padding:0 0 2.4% 0;
  font-size:24px;
  text-align:center;
  font-weight: 700;
  @media screen and (max-width: 480px) {
   text-align: left;
   padding:0 0 3% 0;
   }
 }
.wrapper-inner-body{
  padding-left:19%;
  padding-right:19%;
  display:flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 480px) {
    padding-left:0;
    padding-right:0;
  }
}

.form-inputs-wrapper{
  padding-right:15%;
  padding-left:15%;
  @media screen and (max-width: 480px) {
    padding-left: 6%;
    padding-right:6%;
    }
}

.data-verification-title{
  font-size: 12px;
  font-weight: 700;
  color: #8A6E93
}
.data-verification-value{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin-top: 4px !important;
/* identical to box height */


color: #001021;
}

.hr-line{
    height: 2px;
    border-width: 0;
    color: gray;
    background-color: gray;
    margin: 2.5% 0;
}

.form-data-wrapper{
margin: 2.5% 12.5%;
}

.email-view-outer-wrapper{
  padding-top: 6%;
  padding-bottom: 5%;
  background-color: white;
  padding-left: 17% !important;
  padding-right: 17% !important;
  @media screen and (max-width: 991px) {
   height: 100%;
   display:flex;
   flex-direction: column;
   justify-content: center;
    }
     @media screen and (max-width: 448px) {
   height: 100%;
   display:flex;
   flex-direction: column;
   justify-content: center;
    }

}

.email-wrapper{
  @media screen and (max-width: 448px) {
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
     }
}


.MuiInputLabel-asterisk.MuiFormLabel-asterisk{
  color: $colorAlertNegative;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd{
  height:53px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl{
  height:53px;
}

.MuiOutlinedInput-notchedOutline{
border:2px solid $colorAlertNegative;
}

.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary{
  color:$colorInactiveGrey;
}

.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.Mui-focused{
    color: $colorPrimary;
}



.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-width:2px;
  border-color: $colorInactiveGrey;
  @media screen and (max-width: 480px){
    border-width: 1px;
  }
}
.Mui-disabled.MuiOutlinedInput-notchedOutline{
  border-width:2px;
  border-color: $colorAlertNegative;
  @media screen and (max-width: 480px){
    border-width: 1px;
  }
}
.css-fjgzme-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
  border-width:2px;
  border-color: $colorInactiveGrey !important;
  @media screen and (max-width: 480px){
    border-width: 1px;
  }
}
.back-icon{
  color:$colorPrimaryDark;
  width:23px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd{
  margin:0;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium{
font-size: 20px;
color: $colorInactiveDarkGrey;
}


.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
margin-left:0;
color:$colorInactiveGrey;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
  column-gap:6px;
}

.primary-button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root{
color:white;
border-radius:4px;
width: 100%;
}
.primary-button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root:disabled
{
  color: $colorInactiveGrey;
}


.secondary-button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root{
  border: 1px solid $colorPrimaryDark;
  color: $colorPrimaryDark;
  border-radius: 4px;
  width: 100%;

}



.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
  font-size:24px;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root{
  padding:0;
}

.password-test-icon{
  color:red;
}

.MuiIconButton-label{
  column-gap:9px;
}

.password-tests{
  padding:0 11%;
  @media screen and (max-width: 480px){
    padding-left: 0;
    padding-right: 22%;
    text-align: left;
  }
}

.password-tests-visible{
visibility:visible;
}

.password-tests-hidden{
  visibility:hidden;
  }

  .edit-data-icon{
   padding: 0;
   font-size:21px;
   font-weight:400;
   color: $colorPrimaryDark;
  }
  .edit-data-text{
    font-size:16px;
    font-Weight:400;
    color: $colorPrimaryDark;
  }
.user-form-continue-button{
  position: sticky;
  bottom:0;
  padding-bottom:13%;
  z-index:1;
  padding-top:15px;
  background-color:white;
}

.sup-text{
font-weight:500;
position: relative;
top: -5px;
}

.privacy-notice-text{
  font-size:14px;
  font-weight:400;
  margin-top: 4px;
}

.privacy-notice-url{
  font-size:14px;
  font-weight:700;
  color: $colorPrimary;
}

.login-view-header{
  font-size: 24px;
  font-weight: 400;
  color: $colorPrimary;
}

.patient-screen-content-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.loginhelp-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.signup-background{
  background-color: $colorSecondary;
  display: flex;
  height: 100%;
  flex-direction: row;
  overflow-y: auto;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .image-container {
    flex: 1;

    @media (max-width: 767px) {
      max-height: 360px;
    }

    @media (max-width: 767px) and (max-height: 767px) {
      max-height: 220px;
    }

    @media (max-width: 991px) and (max-height: 480px) {
      max-height: 100vh;
    }
  }

  .text-container-wrapper {
    flex: 1;

    @media (max-height: 780px) {
      flex: 2;
    }
  }
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm{
margin:0;
padding: 8px;
border-radius:  8px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium{
  padding:0;
}

.MuiTypography-root.MuiTypography-h2.MuiDialogTitle-root.dialog-box-title{
  padding:0;
}

.modal-close-icon-wrapper{
  width: fit-content;
  height: fit-content;
  justify-self: end;
  align-self: flex-start;
  line-height: 18px;
}
.modal-header-text{
  padding:16px 24px 24px 24px;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
}

.MuiDialogContent-root.dialog-content-wrapper{
  padding: 0 24px 24px 24px;
}

.modal-close-icon-background{
  background-color: #EFEEF3;
  border-radius:50%;
  border: 1px solid #EFEEF3;
  padding:1px

}

.modal-close-icon{
  color:#929292;
}

.privacy-notice-title{
  padding-left: 28px;
  font-size: 16px;
  font-weight:400;
  color: $colorPrimaryDark;

}

.privacy-notice{
  margin-top: 24px;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin{
  margin-left:2px !important;
  margin-right: 2px !important;
  border-radius: 100px !important;
}

.password-must-message{
  font-size: 14px;
  font-weight: 500;
}

.procedure-login-forgot-password{
  font-size: 12px;
  font-weight: 400;
  color: $colorInactiveGrey
}

.pushed-down-btn-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-help-text{
  font-size: 16px;
  font-weight: 500;
  color: $colorPrimaryDark;
  margin-top:13px;
}

.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.Mui-error{
  color: $colorAlertNegative;
}

.wrapper-banner-container{
  margin-top: 5%;
  width: 100%;
  height: 32px;
  font-weight: 500;
font-size: 16px;
text-align: center;
letter-spacing: 0.15px;
color: $colorBlack;
  background: $colorAlertNegativeLight;
  line-height:35px;
  // @media screen and (max-width: 480px){
  //   padding-right: 18%;
  // }
}

.password-reset-message{
  margin-top:20%;
  font-weight: 400;
  font-size: 18px;
  @media screen and (max-height:480px) and (orientation: landscape){
    margin-bottom:20%;
   }

}

.bold-text{
  font-weight: 500;
}

.not-show-resend-code{
  visibility: hidden;
}

.show-resend-code{
  visibility:visible;
}

.checkbox-design{
  color: $colorPrimaryDark;
}

.MuiOutlinedInput-input.MuiInputBase-input{
  color: $colorPrimaryDark;
}

// .checkbox image.png
.checkbox{
  margin-bottom: 3px;
}
// for checkbox label
.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label{
  margin: 0;
  color: $colorPrimaryDark;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.image-container{
  flex: 1;

  @media (max-width: 992px) {
    max-height: 50vh;
  }

  @media screen and (max-height:480px) and (orientation: landscape){
    width: 50%;
  }

  @media screen and (max-height:480px) and (max-width: 712px) and (orientation: landscape){
    width: 40%;
   }
  }

   .procedure-input-continue-button{
    @media screen and (max-height:480px) and (orientation: landscape){
      margin-top: 16px;
  }
}

.store-image{
  width: 191px;
  @media screen and (max-height:480px) and (orientation: landscape){
    width: 100px;
  }
}

.mandatory-asterisk{
  color: $colorAlertNegative;
  font-size: 16px;
  font-weight: 400;
  top: 0;
}
.app-download-icons{
  margin: 6% 0
}

.not-available-data{
  font-weight: 400;
  font-size: 16px;
  color: $colorInactiveGrey;
}

.data-confirm-body{
  margin-top: 28px;
  @media screen and (max-width: 480px) {

    margin-top:  40px;
  }
}


.MuiOutlinedInput-input.MuiInputBase-input{
  font-size: 16px !important;
  line-height: 32px !important;
}




.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiFormLabel-root{
  font-size: 16px !important
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl{
  font-size: unset !important
}

.MuiCalendarPicker-root{
  font-size: 16px !important;
  .MuiTypography-root.MuiTypography-caption{
    font-size: 16px !important;
  }


  .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin{
    font-size: 16px !important;
  }
  .css-1v994a0{
    font-size: 16px !important;
  }



.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin{
  font-size: 16px !important;
}

}

.MuiTypography-root.MuiTypography-overline{
  font-size: 16px !important;

}

.MuiButton-root.MuiButton-text.MuiButtonBase-root{
  font-size: 16px !important;
}

.continue-button-for-form{
  margin-top: 24px;
  @media screen and (max-height:480px) and (orientation: landscape){
    margin-bottom: 24px;
  }

  @media screen and  (max-width: 480px){
    margin-bottom: 24px;
   }
}

.contact-number{
  a:link{
    color: $colorPrimary;
  }
  a:active{
    color: $colorPrimaryDark;
  }
  a:visited {
    color: $colorPrimaryDark;
  }
  a:disabled{
    color: $colorDisabledGrey;
  }
}
