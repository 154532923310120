@import "/src/assets/stylesheets/variables.scss";

.navbar {
  margin-bottom: 0px !important; // @media screen and (max-width: 572px) {
  //   margin-bottom: 0px !important;
  // }
  // @media screen and (min-width: 572px) and (max-width: 736px) {
  //   margin-bottom: 5px !important;
  // }
  @media screen and (max-width: 768px) {
    min-height: 60px !important;
  }
}

.custom-nav-bar {
  padding: 0 20px !important;
  height: 65px;

  .menu-dropdown-link {
    font-size: 14px;
  }

  .dropdown {
    float: right;
  }

  .custom-dropdown {
    padding: 0;
    height: 100%;

    .dropdown-menu {
      // right: -30% !important;
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

      &::before,
      &::after {
        right: 32px !important;
      }
    }
  }

  .nav-notifications {
    font-size: 14px;
    padding: 0 20px;
    margin-left: 20px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.show {
      background: $colorDisabledGrey;
    }

    > .dropdown-menu {
      right: 0 !important;
      left: auto;
    }

    .dropdown-toggle:after {
      vertical-align: 3px;
    }
  }

  .navbar-profile {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-font-a-profile {
      &::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
      }
    }

    &.show {
      background: $colorDisabledGrey;
    }
  }

  li.nav-dropdown-scrollable > ul.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }

  .navbar-nav > li > a {
    padding: 10px;
  }

  .navbar-header {
    margin: 15px 0px 10px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .mms-logo {
        height: 30px;
      }
    }
  }

  .navbar-brand {
    padding: 10px 20px 10px 20px;
    img {
      width: 200px;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    .navbar-brand {
      padding: 5px;
      img {
        margin-left: 8px;
      }
    }
    .navbar-header {
      width: 50px;
      float: left;
    }
    .navbar-nav {
      margin: -58px -15px !important;
    }
  }
}

.header-container .nav {
  li {
    &:focus {
      outline: none;
    }
    a {
      line-height: 32px !important;
      &:focus {
        outline: none;
      }
    }
  }
}
