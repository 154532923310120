@import "/src/assets/stylesheets/variables.scss";

.consent-footer{
  background-color: $colorPrimary;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 16px 24px;
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    top: 0;
    bottom: auto;
  }
}

.agree{
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 10px 50px;
  background: $colorWhite;
  color: $colorPrimary;
  border-radius: 4px;

  @media screen and (max-width: 991px) {
    margin-left: 0px;
  }
}
.pop-up-header{
  font-weight: 400;
  font-size: 22px;
  color: $colorWhite;
  z-index: 1 !important;
}
.consent-text {
  display: flex;
  color: $colorWhite;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  z-index: 1 !important;
  margin-top: 6px;
}
.consent-text-wrapper{
  margin-top: -5px;
  flex: 8;

  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
  }
}
.consent-buttons-wrapper{
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0% 3%;
  margin-right: 7px;

  @media screen and (max-width: 991px) {
    padding: 0% 15%;
  }
}
