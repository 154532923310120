@import "/src/assets/stylesheets/variables.scss";
@import "/src/assets/stylesheets/fonts.scss";
.form-control {
  height: 30px;
  font-size: 14px;
  padding: 5px;
  line-height: 32px;

  &:focus {
    box-shadow: none;
    border-color: $colorPrimary
  }
}

.btn-close-white {
  opacity: 1;
}

select.form-control {
  background: url("../images/downchevronfilledgray.png") no-repeat right 5px
    center;
  background-size: 12px;
}

label {
  font-weight: 500;
  margin-bottom: 5px;
}

.btn-primary {
  background: $colorPrimaryGradient;
  border: 1px solid !important;
  border-image: $colorPrimaryGradient;
  height: 35px;
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    background: $colorPrimary !important;
    // border: 1px solid $colorPrimary !important;
    border: 1px solid !important;
    border-image: $colorPrimary;
    .icon:before {
      color: $colorWhite;
    }
  }
}

.btn-primary-appointment-card {
  background-color: $colorAlertPositive;
  //border: 1px solid $colorPrimaryGradient;
  color: $colorWhite;
  width: 150px;
  height: 27px;
  border-radius: 13.5px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 25px;
  }
  &:hover {
    background-color: #1c7f83;
    //border: 1px solid $colorPrimary;
    .icon:before {
      color: $colorWhite;
    }
  }
}

.btn-default,
.btn-default:focus {
  height: 35px;
  border: 1px solid $colorPrimary;
  color: $colorPrimary;
  &.red {
    border: 1px solid $colorAlertNegative;
    color: $colorAlertNegative;
  }
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  background: $colorWhite;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    border: 1px solid $colorPrimaryGradient;
    .icon:before {
      color: $colorWhite;
    }
  }
}

.btn-default-appt-card,
.btn-default-appt-card:focus {
  width: 150px;
  height: 27pxpx;
  border: 1px solid $colorPrimaryGradient;
  color: $colorPrimaryGradient;
  &.red {
    border: 1px solid $colorAlertNegative;
    color: $colorAlertNegative;
  }
  border-radius: 13.5px;
  padding: 0px 25px;
  font-weight: 400;
  font-size: 16px;
  background: $colorWhite;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    border: 1px solid #1c7f83;
    .icon:before {
      color: $colorWhite;
    }
  }
}

.btn-danger,
.btn-danger:focus {
  height: 35px;
  border: 1px solid $colorAlertNegative;
  color: $colorAlertNegative;
  &.red {
    border: 1px solid $colorAlertNegative;
    color: $colorAlertNegative;
  }
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  background: $colorWhite;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    border: 1px solid $colorAlertNegative;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: $colorAlertNegative !important;
    &.red {
      background-color: $colorAlertNegative !important;
      border: 1px solid $colorAlertNegative;
    }
    color: $colorWhite !important;
    border: 1px solid $colorAlertNegative;
  }
}

.btn-primary:active,
.btn-primary:focus {
  background-color: $colorPrimaryDark !important;
  &.red {
    background-color: $colorAlertNegative !important;
    border: 1px solid $colorAlertNegative;
  }
  color: $colorWhite !important;
  border: 1px solid $colorPrimaryDark;
}

.btn-primary-appointment-card:active,
.btn-primary-appointment-card:focus {
  // background-color: #1c7f83 !important;
  &.red {
    background-color: $colorAlertNegative !important;
    border: 1px solid $colorAlertNegative;
  }
  color: $colorWhite !important;
  border: 1px solid $colorPrimary;
}

.btn-default:hover,
.btn-default:active {
  background-color: $colorPrimary !important;
  &.red {
    background-color: $colorAlertNegative !important;
    border: 1px solid $colorAlertNegative;
  }
  color: $colorWhite !important;
  border: 1px solid $colorPrimary;
}

.btn-default-appt-card:hover,
.btn-default-appt-card:active {
  background-color: $colorPrimary !important;
  &.red {
    background-color: $colorAlertNegative !important;
    border: 1px solid $colorAlertNegative;
  }
  color: $colorWhite !important;
  border: 1px solid $colorPrimary;
}

.btn-default-compressed {
  height: 30px;
  border: 1px solid $colorPrimary;
  color: $colorPrimary;
  border-radius: 20px;
  padding: 0px 20px;
  font-weight: 300;
  font-size: 14px;
  background: $colorWhite;
}

.btn-default-compressed:hover,
.btn-default-compressed:active,
.btn-default-compressed:focus {
  background-color: $colorPrimary !important;
  &.red {
    background-color: $colorAlertNegative !important;
    border: 1px solid $colorAlertNegative;
  }
  color: $colorWhite !important;
  border: 1px solid $colorPrimary;
}

* {
  font-family: "HelveticaNeue", sans-serif !important;
}

.btn-default-compressed[disabled]:hover,
.btn-default-compressed[disabled] {
  background-color: $colorWhite !important;
  border: 1px solid $colorDisabledGrey !important;
  color: $colorInactiveGrey !important;
}

.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover {
  background-color: $colorDisabledGrey !important;
  border: 1px solid $colorDisabledGrey !important;
}

.btn.btn-primary-appointment-card[disabled],
.btn.btn-primary-appointment-card[disabled]:hover {
  background-color: $colorWhite !important;
  border: 1px solid $colorInactiveGrey !important;
  color: $colorInactiveGrey !important;
  .icon:before {
    color: $colorInactiveGrey;
  }
}

.btn.btn-default[disabled],
.btn.btn-default[disabled]:hover {
  background-color: $colorWhite !important;
  border: 1px solid $colorInactiveGrey !important;
  color: $colorInactiveGrey !important;
  .icon:before {
    color: $colorInactiveGrey;
  }
}

.btn.btn-default[disabled],
.btn.btn-default[disabled]:hover {
  background-color: $colorWhite !important;
  border: 1px solid $colorInactiveGrey !important;
  color: $colorInactiveGrey !important;
  .icon:before {
    color: $colorInactiveGrey;
  }
}

.btn.btn-default-appt-card[disabled],
.btn.btn-default-appt-card[disabled]:hover {
  background-color: $colorWhite !important;
  border: 1px solid $colorInactiveGrey !important;
  color: $colorInactiveGrey !important;
  .icon:before {
    color: $colorInactiveGrey;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-weight: 300;
  &:focus {
    outline: 0 !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: none;
}

.navbar-default {
  background-color: #fff;
  border-color: #e7e7e7;
}

.badge {
  margin-left: 5px !important;
  font-weight: 100;
  &.orange {
    background-color: $colorAlertNeutral;
  }
  &.red {
    background-color: $colorAlertNegative !important;
    color: $colorWhite !important;
  }
}
body { color: $colorBlack; overflow: auto; }
