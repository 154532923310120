@import "/src/assets/stylesheets/variables.scss";

.home-page-container {
  .dashboard-navigation-container {
    border: 1px solid $colorPrimaryDark;
    margin-bottom: 16px;
    border-radius: 4px;
    margin-top: -5px;

    @media print {
      display: none;
    }

    .dashboard-navigation-item {
      height: 40px;
      border-right: 1px solid $colorPrimaryDark;
      color: $colorPrimaryDark;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @media screen and (min-width: 769px) and (max-width: 1048px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &:hover {
        @keyframes exp {
          from {
            background-color: rgba($colorSecondaryDark, 0.5);
          }
          to {
            background-color: $colorSecondaryDark;
          }
        }

        animation-name: exp;
        animation-duration: 1s;
        background-color: $colorSecondaryDark;
        color: $colorPrimaryDark;
      }
      &:active {
        // @keyframes exp {
        // 	from {background-color: rgba($colorPrimaryDark, 0.5);}
        // 	  to {background-color: $colorPrimaryDark ,0.2}
        //   }

        //   animation-name: exp;
        //   animation-duration: 1s;
        background-color: $colorPrimaryDark;
        color: $colorWhite;
      }

      &:last-child {
        border-right: none;
      }
      &.disabled {
        color: $colorDisabledGrey;
        cursor: default;
      }

      .icon {
        position: relative;
        top: 2px;
      }

      .manage-patient-advocate-icon:before {
        top: 6px;
      }

      .manage-patient-advocate-label {
        @media screen and (max-width: 1008px) {
          position: relative;
          top: -8px;
        }
      }
    }
  }
  .dashboard-body-containers {
    display: flex;
    gap: 20px;
    flex: 1;
    height: calc(100% - 65px);

    .tip {
      top: 8px;
      left: 26px;
      transform: rotate(-90deg);
    }

    .tooltip-content {
      width: 200px;
      left: 35px;
      top: -10px;
    }

    .card .icons-container .item {
      .tip {
        top: 24px;
        left: 7px;
        transform: rotate(0deg);
      }

      .tooltip-content {
        width: 170px;
        top: 30px;
        left: 0;
      }
    }
  }
  .patient-followup-container {
    background-color: $colorSecondary;
    border: 1px solid $colorDisabledGrey;
    border-radius: 4px;
    overflow-y: clip;
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: calc(100% - 150px);
    }

    .glyphicon {
      margin: 0 20px;
    }
    @media print {
      width: 100%;
      height: 80%;
    }

    .patient-card-container {
      background: $colorSecondary;
      padding: 5px 0px 10px 0px;
      flex: 1;
      overflow: auto;
      margin-top: 10px;
      font-size: 12px;
      line-height: 18px;

      & > :first-child > .to-click {
        margin-top: 0;
      }

      .card:hover {
        background: $colorWhite;
      }

      .row.zero-margin {
        margin-inline: 0;
      }

      .card {
        @media screen and (max-width: 1024px) {
          padding-left: 10px;
        }
      }
    }

    .icon-font-a-stethoscope:before {
      font-size: 15px;
      top: 2px;
    }
    .providers-selector-pill {
      .providers-selector-pill--select {
        border: 1px solid $colorPrimary;
        border-radius: 20px;
        background-color: $colorPrimary;
        color: $colorWhite;
        font-size: 16px;
        .multi-select__control {
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            display: block;
          }
          border: none;
          background-color: transparent;
          cursor: pointer;
          border-radius: 20px !important;
          //   .multi-select__control--is-focused.multi-select__control--menu-is-open{
          // 	border-color: 1px solid red !important;
          //   }
          .multi-select__placeholder {
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              /* IE10+ CSS styles go here */
              display: inline;
            }
            max-width: calc(100% - 8px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 300;
            color: inherit;
            left: 0;
            right: 0;
            margin-left: 8px;
            margin-top: 0px;
            transform: none;
            text-align: center;
          }
          .multi-select__indicators {
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              display: inline-block;
            }
          }
          .multi-select__indicator {
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              position: relative;
              top: 3px;
            }
            color: inherit;
            transition: none;
          }
        }
        .multi-select__indicator-separator {
          display: none;
        }
        .multi-select__menu {
          color: $colorBlack;
          width: 100%;
          z-index: 9;
          .multi-select__option.multi-select__option--is-selected {
            label {
              span {
                &:nth-of-type(2) {
                  font-weight: 400;
                }
              }
            }
          }
          .multi-select__option {
            color: $colorInactiveGrey;
            cursor: pointer;
            input,
            label {
              cursor: pointer;
            }
            label {
              display: flex;
              align-items: center;
              margin-left: 0 !important;
              span {
                &:first-of-type {
                  color: $colorPrimary;
                  &:before {
                    display: flex;
                    font-size: 17px;
                  }
                }
                &:nth-of-type(2) {
                  font-weight: 300;
                  margin-left: 7px;
                }
              }
            }
          }
          footer {
            display: flex;
            justify-content: flex-end;
            padding: 8px 10px;
            border-top: 1px solid $colorPrimary;

            .btn-primary {
              border-radius: 5px;
              height: auto;
              padding: 2px 20px;
              font-size: 14px;
              margin-right: 0;
            }
          }
        }
      }
      .providers-selector-pill--select.has-selected-items {
        background-color: $colorPrimary;
        color: $colorWhite;
      }
      .multi-select__value-container {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          display: inline-block;
        }
        padding: 0 0 0 8px;
      }
      .value-container--value-outer {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: inline;
        }
        justify-content: center;
        color: inherit;
        .value-container--value-label {
          flex: none;
        }
      }
    }
  }

  .track-patients-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid $colorDisabledGrey;
    border-radius: 4px;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0px;
    }

    .track-patient-filter-container {
      border: 1px solid $colorDisabledGrey;
      border-radius: 4px;
      flex: 1;

      .track-patients-results-container {
        overflow: auto;

        .default,
        .custom {
          .icon:before {
            color: $colorPrimaryDark;
          }
        }

        .disabled {
          .icon:before {
            color: $colorDisabledGrey;
          }
        }
        &.with-back-button {
        }
      }
    }

    .add-patient-btn-container {
      width: 48%;
      float: left;
    }

    .survey-btn-container {
      width: 48%;
      float: right;
    }
  }

  .container-title {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-custom-filters-btn {
      font-size: 12px;
      font-weight: 400;
      color: $colorWhite;
      cursor: pointer;

      &:hover {
        background-color: $colorPrimary;
        border: none;
      }

      .icon {
        position: relative;
        top: 1px;

        &:before {
          font-size: 20px;
        }
      }
    }
  }

  .filter-back-button-container {
    padding-bottom: 25px;
    padding-left: 20px;
    padding-top: 20px;
    position: relative;

    .filters-back-btn {
      font-size: 16px;
      font-weight: 300;
      color: $colorPrimary;
      position: absolute;
      top: 5px;
      bottom: 10px;
      cursor: pointer;

      &:hover {
        color: $colorPrimaryDark;
      }

      .icon {
        position: relative;
        top: 1px;

        &:before {
          font-size: 18px;
        }
      }
    }
  }

  .track-patients-container {
    .container-title {
      border-bottom: 1px solid $colorDisabledGrey;
    }
  }

  .no-padding {
    padding: 0px;
  }

  .search-container {
    width: 48%;
    margin: 0 1%;
    float: left;
    position: relative;
    margin-bottom: 20px;
  }

  .button-container {
    width: 48%;
    margin: 0 1%;
    float: left;
  }

  .patient-card-container-print--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    height: fit-content;

    .card-list-item {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }
}

.procedure-select,
.location-select {
  // border: none;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid $colorDisabledGrey;
  margin-bottom: 10px;
  font-size: 14px;
}

.procedure-select:focus,
.location-select:focus {
  border-color: $colorDisabledGrey;
  box-shadow: none;
}

.procedure-date-info {
  padding: 5px 10px;
  background-color: $colorDisabledGrey;
  font-size: 12px;
  margin: 15px 0;
  color: $colorInactiveGrey;
  border-radius: 4px;
}

.add-patient-modal {
  min-height: 320px;

  .modal-subheader-content {
    background: $colorPrimaryLight;
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding-left: 5px;
    line-height: 40px;
    display: flex;

    > span {
      flex: 1;
      padding: 0 15px;
    }

    @media screen and (max-width: 767px) {
      margin-top: -15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    .steps-count-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;

      .steps-count {
        width: 25px;
        height: 25px;
        border: 1px solid $colorPrimary;
        text-align: center;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    .active-step-count {
      background: $colorSecondaryDark;
    }

    .separation {
      // margin: 0px 3px;
    }
  }

  .icon-font-a-check-mark:before,
  .icon-font-a-check-box:before {
    color: $colorPrimary;
  }
  .check-box-label {
    position: relative;
    top: -4px;
    left: 4px;
    font-size: 12px;
  }
}
.show-only-in-print {
  visibility: hidden;
  display: none;
}
@media print {
  body * {
    visibility: hidden;
  }
  .print-overflow-visible {
    overflow: visible;
  }
  .hide-in-print {
    display: none !important;
    visibility: hidden !important;
  }
  .img-cell {
    width: 90px;
    height: 100px;
    padding: 10px 0px 10px 10px;
  }
  img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }
  .show-only-in-print {
    visibility: visible;
    display: inline;
  }
  .all-border {
    border: 1px solid $colorDisabledGrey;
  }
  .show-only-in-print.patient-procedure-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
    overflow: visible !important;
    .hide-in-print {
      display: none;
    }
  }
  .logo-in-print {
    padding: 10px 0px 10px 10px;
  }
  .index-in-print {
    line-height: 90px;
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    page-break-inside: avoid;
  }
  .patient-card-in-print {
    padding: 0;
    width: 80%;
  }
  .topborder {
    border-top: 1px solid $colorDisabledGrey;
    margin: 10px 12px;
  }
}

