// Grid variables
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

//colors

//primary
$colorPrimary: #1010EB;
$colorPrimaryDark: #170F5F;
$colorPrimaryLight: #E1F8FE;

//secondary
$colorSecondary: #F5F5F5;
$colorSecondaryDark: #C6D4FB;
$colorSecondaryLight: #E5F7FD;

//neutral
$colorInactiveGrey: #B2B2B2;
$colorDisabledGrey: #E5E5E5;
$colorInactiveDarkGrey: #737373;

//alert colors
$colorAlertPositive: #59A719;
$colorAlertPositiveLight: #EFF9E3;
$colorAlertPositiveDark: #376415;

$colorAlertNeutral: #F7A800;
$colorAlertNeutralLight: #FFF9DB;
$colorAlertNeutralDark: #CC7A00;

$colorAlertNegative: #E40A1A;
$colorAlertNegativeLight: #FFEFEE;
$colorAlertNegativeDark: #A3001E;

//B&W
$colorBlack: #3C3C3C;
$colorWhite: #FFFFFF;

//gradient
$colorPrimaryGradient: #1010EB;
$colorSecondaryGradient: #1010EB;
// Colors
$faintGrey: #f7f2f2; //only used in ehr (MMS)????
$infoBackgroundColor: #FFF6EA; //patientApp used parked
$violetColor: #8973eb; //patientApp used parked
$lightTabColor: rgba(0, 178, 208, 0.1); //only used in ehr (MMS)????
$darkGreen:#4EB189; //patientApp used parked
$blueBorderColor: #4A90E2; ////patientApp used parked
$masqueradingHeaderColor: #F7DEBD;// patientApp used parked
$tooltipColor: #007b92;// park know to check
$telemedicineBackgroundColor: #06335a;//colorPrimaryDark
$patientAppointmentCard: #90989F;//patient App only
$salesforceBlueColor: #3F90D4; //salesforce checkbox on patient details page
$progressBarBackground: #337ab7;

$painReductionColor100: #A0D5C1;
$painReductionColor90: #A9C7A9;
$painReductionColor80: #B9C590;
$painReductionColor70: #E9CF83;
$painReductionColor60: #EEC368;
$painReductionColor50: #E5B466;
$painReductionColor40: #FC9C39;
$painReductionColor30: #F59746;
$painReductionColor20: #EA8C68;
$painReductionColor10: #F6806D;
$painReductionColor00: #F37980;

// Theme Colors only used in self signup
$themeRedColor: #D03D4D;
$themeBlueColor: #17088D;
$themeYellowColor: #FF9D1D;
$themePurpleColor: #FB647C;
$themeGrayColor: #5f5b60;
$themeLightGrayColor: #b9b9ba;
$themeOrangeColor: #EE7066;

// Font Names
$appFontName: 'Helvetica Neue';
// Font Sizes
$font-size-xxxxs: 12px;
$font-size-xxxs: 14px;
$font-size-xxs: 16px;
$font-size-xs: 18px;
$font-size-s: 20px;
$font-size-m: 22px;
$font-size-l: 24px;
$font-size-xl: 26px;
$font-size-xxl: 28px;
$font-size-header-xxxl: 30px;
$font-size-header-xxxxl: 32px;
// Font Weights
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
