
@import "../../assets/stylesheets/variables";

.lookup {
  width: 460px;

  &.navbar-left {
    float: right !important;
  }

  .form-group {
    .help-block {
      font-size: 12px;
      font-weight: 100;
    }
  }

  input {
    border: 1px solid $colorPrimary;
    background: $colorWhite;
    border-radius: 5px 0px 0px 5px;
    margin: 0;
    height: 32px;
    font-size: 14px;
    min-width: 260px;

    @media screen and (min-width: 821px) and (max-width: 1024px) {
      min-width: 165px;
      font-size: 12px;
    }
    @media screen and (max-width: 820px) {
      min-width: 105px;
      font-size: 12px;
    }
  }

  .help-block {
    position: absolute;
    top: 80%;
    font-size: 11px !important;
  }

  .btn-primary {
    background: $colorPrimaryGradient;
    border: 1px solid $colorPrimary !important;
    border-image: $colorPrimaryGradient;
    vertical-align: top;
    height: 32px;
    line-height: 20px;
    padding: 5px 12px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 0px 5px 5px 0px;
    margin-left: -1px;

    &:hover {
      border: 1px solid $colorPrimary !important;
    }
  }
}

@media screen and (max-width: 875px) {
  .navbar-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
