.dropdown-menu {
  top: 90% !important;
  right: -10% !important;
  padding: 4px 0 0 0 !important;
}

.dropdown-toggle {
  &::before {
    content: none !important;
  }
}

.nav-notification-icon::after {
  content: none;
}
