@charset "UTF-8";

@font-face {
  font-family: "mms-font-z";
  src:url("../fonts/mms-font/mms-font-z.eot");
  src:url("../fonts/mms-font/mms-font-z.eot?#iefix") format("embedded-opentype"),
    url("../fonts/mms-font/mms-font-z.woff") format("woff"),
    url("../fonts/mms-font/mms-font-z.ttf") format("truetype"),
    url("../fonts/mms-font/mms-font-z.svg#mms-font-z") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "mms-font-z" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-font-z-"]:before,
[class*=" icon-font-z-"]:before {
  font-family: "mms-font-z" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font-z-info:before {
  content: "\61";
}
