@import "./src/assets/stylesheets/variables.scss";

.content-administration {
  margin-top: 1.3rem;
  .add-new-link {
    color: $colorPrimary;
    cursor: pointer;
    margin-right: 20px;

    .icon {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }

  table {
    width: 100%;
    border-bottom: 1px solid lightgray;
  }

  .sticky-header {
    background: $colorWhite;
    color: $colorBlack;
    top: 0px;
    position: sticky;
    z-index: 2;
  }

  .ui-table {
    max-height: calc(100vh - 250px);
    display: block;
    overflow: scroll;
     width: 100%;
  }

  .inline-btn {
    margin-right: 10px;
  }
}

.content-error {
  font-size: 24px;
  font-weight: 600;
  margin-top: 50px;
  text-align: center;
}

.filter-container-wrapper{
  .filters-container {
    padding:0 5px;
    .filters-label {
      font-size: 16px;
      padding: 15px 0px;
    }
    .col-xs-12{
      label{
        padding-top: 10px;
      }
    }
    .filter-wrapper{
      padding-bottom: 20px;
      label{
        font-weight: 300;
      }
    }
    .btn-wrapper {
      margin-top: 10px;
      text-align: center;
      .btn {
        margin: 5px 5px;
      }
    }
  }
}

.record-list-wrapper {
  border-left: 1px solid $colorInactiveGrey;
  margin-top: 10px;
  border-left: 1px solid;
  height: calc(100vh - 130px);
  margin-left: 25%;
  .pagination {
    margin: 0;
    float: right;
  }
}
.admin-navigation-div {
  margin-top: -75px;
  padding-top: 25px;
  position: fixed;
  z-index: 1;
  width: 98.8%;
  background-color: white;
  .admin-navigation-section {
    border: 1px solid $colorPrimary;
    border-radius: 4px;
    .nav>.active {
      outline: none;
      text-decoration: none;
      color: $colorPrimary;
      font-weight: 500;
      background: $lightTabColor;
    }
    .nav>:first-child {
      border-right: 1px solid $colorPrimary;
    }
    .nav {
      a {
        display: inline-block;
        color: $colorPrimary;
        text-decoration: none;
        width: 50% !important;
        padding: 5px 15px;
        text-align: center;
      }
      .icon-label {
        font-size: 14px;
        line-height: 2;
      }
      .icon::before{
        font-size: 18px;
        top: 4px;
      }
    }
  }
}

.admin-title-container {
  padding-right: 20px;
  padding-left: 20px;
  // overflow-x: hidden;
  .tab-details-title{
    padding: 2px 1px;
    .title{
      vertical-align: text-bottom;
    }
  }
  .tab-content-wrapper {
    margin-top: 35px;
  }
}
.tab-details-title{
  padding: 2px 1px;
  .title{
    vertical-align: text-bottom;
  }
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
 margin: 0;
}

.account-status, .account-status-head{
 text-align: center;
}