@import "/src/assets/stylesheets/variables.scss";

.pre-login-footer-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;

  div {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  background: $colorSecondary;
  padding: 15px;
  font-size: 12px;
  font-weight: 300;

  @media screen and (min-width: 736px) and (max-width: 768px) {
    font-size: 9px;
  }

  .contact-us {
    color: $colorPrimary;
    float: left;
    font-weight: 700;
  }

  .about-us {
    color: $colorPrimary;
    margin-left: 10px;
    float: left;
  }

  .dot {
    margin-left: 10px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $colorInactiveGrey;
    float: left;
  }

  .contact-number {
    color: $colorInactiveGrey;
    margin-left: 10px;
    float: left;
  }

  .copy-right-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    text-align: right;
    color: $colorInactiveGrey;
  }
}
