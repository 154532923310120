@import "/src/assets/stylesheets/variables.scss";

.masquerading-header {
  //margin: 0 15px;
  padding: 5px 5px;
  text-align: center;
  background: $masqueradingHeaderColor;
  @media screen and (max-width: 572px) {
    font-size: 13px;
  }
}
