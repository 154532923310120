@import "/src/assets/stylesheets/variables.scss";
.header-container {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  img {
    width: 155px; // width: 200px;
  }
  .navbar-brand {
    height: auto;
    margin-left: -10px !important;
    padding: 5px 15px;
  }
  .navbar-right {
    margin-right: 0;
  }
  .nav {
    li {
      a {
        line-height: 42px;
      }
    }
  }
  .user-info-container {
    min-width: 380px;
    padding: 10px 15px;
    .user-image img {
      max-width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .user-name {
      font-weight: bold;
      margin: 2px 0;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .user-email,
    .user-type {
      margin: 2px 0;
      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-information {
      padding: 0;
      padding-right: 5px;
      .btn-primary {
        line-height: 22px;
        height: 34px;
        margin-top: 2px;
      }
    }
  }
  .nav-notification-icon {
    position: relative;
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(1);
      }
    }
    .pending-notifications-badge {
      position: absolute;
      bottom: 50%;
      left: 10px;
      background: $colorAlertNegative;
      line-height: 20px;
      border-radius: 10px;
      color: $colorWhite;
      font-size: 12px;
      font-weight: 300;
      display: block;
      min-width: 20px;
      height: 20px;
      text-align: center;
      padding: 0 2px;
      animation: pulse 0.5s 1;
      @media screen and (max-width: 572px) {
        left: 26px;
        top: 15px;
      }
    }
    .animate-badge {
      animation: pulse 0.5s 1;
    }
  }
  .nav-notifications-container {
    min-width: 380px;
    height: 400px;
    margin-top: -5px;
    .nav-dropdown-header {
      background: $colorPrimaryLight;
      line-height: 50px;
      padding: 0 20px;
      .nav-dropdown-title {
        font-size: 20px;
        font-weight: 500;
      }
      .see-all-link {
        font-size: 14px;
        color: $colorPrimary;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        &.disabled {
          color: $colorDisabledGrey;
          cursor: default;
        }
      }
    }
    .nav-dropdown-body {
      overflow-y: auto;
      max-height: 350px;

      .nav-notifications-empty-container {
        text-align: center;
        font-size: 20px;
        font-weight: 100;
        margin-top: 20px;
        .empty-container {
          .text {
            font-size: 12px;
            font-weight: 300;
            color: $colorDisabledGrey;
          }
        }
        img {
          width: 75px;
        }
      }
      .nav-notification-card {
        padding: 10px 15px;
        font-weight: 100;
        border-bottom: 1px solid $colorDisabledGrey;
        font-size: 14px;
        .timestamp {
          margin-top: 10px;
          color: $colorInactiveGrey;
          font-weight: 300;
        }
        .content {
          color: $colorBlack;
          font-weight: 300;
        }
        &.unread {
          .content,
          .timestamp {
            color: $colorBlack;
            font-weight: 500;
          }
        }
      }
      .Wrapper-dyuznp {
        margin: 1em auto;
        width: 24px;
        height: 24px;
      }
    }
  }
  .dropdown-menu {
    &:before {
      content: ' ';
      position: absolute;
      top: -10px;
      right: 25px;
      width: 0;
      border-bottom: 10px solid $colorDisabledGrey;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      content: ' ';
      position: absolute;
      top: -8px;
      right: 25px;
      width: 0;
      border-bottom: 10px solid $colorWhite;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .notification-navbar {
    position: relative;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.show {
      background: $colorDisabledGrey;
    }

    a {
      text-align: center;
      &:hover {
        text-decoration: none;
      }
    }
    .dropdown-menu {
      &:after {
        border-bottom: 10px solid $colorPrimaryLight;
      }
    }
  }
  .dropdown-menu .divider {
    margin-bottom: 5px;
  }
  .menu-dropdown-link {
    padding: 8px 16px;
    color: $colorPrimaryGradient;
    &:hover {
      color: $colorPrimaryGradient;
      background: $colorSecondary;
    }
  }
  .dropdown-menu>.active>a,
  .dropdown-menu>.active>a:hover,
  .dropdown-menu>.active>a:focus {
    color: $colorWhite;
    text-decoration: none;
    background-color: $colorPrimary;
    outline: 0;
  }
}

@media screen and (max-width: 736px) {
  .custom-dropdown {
    .dropdown-menu {
      position: absolute !important;
      left: -250px !important;
      background: $colorWhite !important;
      width: 320px !important;
      -webkit-background-clip: padding-box !important;
      background-clip: padding-box !important;
      border: 1px solid $colorDisabledGrey !important;
      border: 1px solid $colorDisabledGrey !important;
      border-radius: 4px !important;
      -webkit-box-shadow: 0 6px 12px $colorDisabledGrey !important;
      box-shadow: 0 6px 12px $colorDisabledGrey !important;
    }
  }
}
