@import "/src/assets/stylesheets/variables.scss";

.conversation-list-row {
  .conversation {
    display: flex;

    .conversation-list-status {
      padding: 0 16px;
      position: relative;
    }

    .conversationlistcard--conversation-content {
      flex: 1;
    }

    .conversationlistcard--conversation-action {
      max-width: 300px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 16px;

      > span {
        font-size: 14px;
      }
    }
  }
  .conversation-title {
    font-weight: 700;
  }
  .date {
    color: $colorDisabledGrey;
    font-weight: 300;
  }
  .sent-on {
    font-weight: 100;
  }
  .dot:before {
    content: " \2022 ";
    color: $colorDisabledGrey;
    margin-left: 2px;
    margin-right: 5px;
  }
  .margin-top-5 {
    margin-top: 5px;
  }
  .font-weight-300 {
    font-weight: 300;
  }
  .font-size-sm {
    font-size: 12px;
  }
  .app-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
  }
}
